import { AddressDocument } from '@models/address';
import {
  CreateUserDocumentArgs,
  GetUserArgs,
  InteractionDoc,
  notificationSettings,
  PublicUserDocument,
  UpdateUserDocArgs,
  updateUserDocArgsSchema,
  UpdateUserRoleArgs,
  UserAttribution,
  UserDocument,
  UserProfile,
  userSchema,
  UserSizes,
} from '@models/user';
import { completeRegistration } from '@util/analytics';
import { db, FirebaseCallable, functions, httpsCallable } from '@util/firebase';
import { client } from '@util/getTypesense';
import { logError } from '@util/logError';
import { slugify } from '@util/urlHelpers';
import AuthProvider from 'context/AuthContext';
import { deleteUser, User } from 'firebase/auth';
import {
  arrayRemove,
  arrayUnion,
  collection,
  CollectionReference,
  deleteField,
  doc,
  DocumentData,
  FieldValue,
  getDoc,
  getDocs,
  getDocsFromCache,
  increment,
  limit,
  namedQuery,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import Cookies from 'js-cookie';
import { ProductDocument } from 'models/product';
import { Bike, SellerType, UserBike } from 'models/shared';
import { cleanLocation, getProductsBySellerId } from '../products';
import { ShipEngineValidateResponse } from '../shipengine';
import dayjs from 'dayjs';

export const userRef = collection(
  db,
  'users'
) as CollectionReference<UserDocument>;

const userCol = collection(db, 'users') as CollectionReference<UserDocument>;
const getRef = (uid: string) => doc<UserDocument, DocumentData>(userCol, uid);

export async function getUserDocument(
  args: GetUserArgs
): Promise<UserDocument | null> {
  if (!args.uid) return null;
  const docSnap = await getDoc(doc(db, 'users', args.uid));
  const user = (docSnap.data() as UserDocument) ?? null;
  return user;
}

export async function getUserByAccountId(accountId: string) {
  const q = query(userRef, where('account_id', '==', accountId), limit(1));
  const docSnap = await getDocs(q);
  const user = docSnap.size ? docSnap.docs[0].data() : null;
  return user;
}

export async function getUserById(uid: string) {
  if (!uid) return null;
  const user = await getDoc(getRef(uid));
  return user.data() as UserDocument;
}

export function getUserByIdQuery(uid: string) {
  return query(userRef, where('uid', '==', uid), limit(1));
}

export async function getUsersByIdsFromTypesense(
  uids: string[]
): Promise<PublicUserDocument[]> {
  const results = await client

    .collections('sellers')
    .documents()
    .search({
      q: '*',
      query_by: 'username',
      filter_by: `uid:[${uids.join(', ')}]`,
      per_page: uids.length,
    });
  const users = results.hits?.map((h) => h.document as any) ?? [];
  return users;
}

export async function getUsersByIds(uids: string[]): Promise<UserDocument[]> {
  const q = query(userRef, where('uid', 'in', uids.slice(0, 10)));
  const snap = await getDocs(q);
  return snap.docs.map((doc) => doc.data());
}

export async function getUserByEmail(email: string) {
  const q = query(userRef, where('email', '==', email), limit(1));
  const docSnap = await getDocs(q);
  const user = docSnap.size ? docSnap.docs[0].data() : null;
  return user;
}

export async function getUserByPhone(phone: string) {
  const { data } = await httpsCallable<{ phone: string }, UserDocument>(
    functions,
    FirebaseCallable.getUserByPhone
  )({ phone });
  return data;
}

export function updateUserByKey<K extends keyof UserDocument>(
  uid: string,
  key: K,
  value: UserDocument[K]
): Promise<void> {
  return updateDoc(getRef(uid), { [key]: value });
}

export function updateUserMetricsByKey<K extends keyof UserDocument['metrics']>(
  uid: string,
  key: K,
  value: UserDocument['metrics'][K] | FieldValue
): Promise<void> {
  return updateDoc(getRef(uid), { [`metrics.${key}`]: value });
}

export async function removeUserKey(uid: string, key: keyof UserDocument) {
  return await updateDoc(getRef(uid), {
    [key]: deleteField(),
  });
}

export function addUserAddress(uid: string, address: AddressDocument) {
  return updateDoc(getRef(uid), { addresses: arrayUnion(address) });
}

export async function removeUserAddress(uid: string, address: AddressDocument) {
  // check if theres only one address
  const user = await getUserById(uid);
  if (!user) return;
  if (!user.addresses || user.addresses.length === 1) return;

  return updateDoc(getRef(uid), { addresses: arrayRemove(address) });
}

export async function getUserByUsernameSlug(
  username_slug: string
): Promise<UserDocument | null> {
  if (!username_slug) return null;
  const q = query(
    userRef,
    where('username_slug', '==', username_slug),
    limit(1)
  );
  const docSnap = await getDocs(q);
  const user = docSnap.size ? docSnap.docs[0].data() : null;
  return user;
}

export async function getUserByUsername(
  username: string
): Promise<UserDocument | null> {
  if (!username) return null;
  const q = query(userRef, where('username', '==', username), limit(1));
  const docSnap = await getDocs(q);
  const user = docSnap.size ? docSnap.docs[0].data() : null;
  return user;
}

export async function getUserByUsernameSlugFromTypesense(
  username_slug: string
) {
  const results = await client
    .collections('sellers')
    .documents()
    .search({
      query_by: 'username_slug',
      q: '*',
      filter_by: `username_slug:=${username_slug}`,
      split_join_tokens: 'off',
    });
  const user = results.hits?.filter(
    (h) => (h.document as PublicUserDocument).username_slug === username_slug
  )[0]?.document;
  if (!user) return null;
  return user as PublicUserDocument;
}

export async function getAuthUserByUid(uid?: string) {
  if (!uid) return null;
  const { data } = await httpsCallable<{ uid: string }, User>(
    functions,
    'v2_getAuthUserByUid'
  )({ uid });
  return data;
}

export async function createUserDocument(
  args: CreateUserDocumentArgs,
  method: AuthProvider | 'email' | 'anonymous'
) {
  await setDoc(doc(db, 'users', args.uid), args);
  if (method !== 'anonymous') {
    await completeRegistration(args.uid, method, args.email);
  }
  return args;
}

export async function updateUserRole(args: UpdateUserRoleArgs) {
  if (!args.uid) return null;
  const userRef = doc(db, 'users', args.uid);
  await updateDoc(userRef, {
    roles: args.newRole,
  });
}

export async function updateUsername(uid: string, username: string) {
  const userRef = doc(db, 'users', uid);
  const username_slug = await getUniqueSlug(username, uid);
  return updateDoc(userRef, { username, username_slug });
}

export async function updateUserDoc(uid: string, args: UpdateUserDocArgs) {
  if (!uid) return null;
  const userRef = doc(db, 'users', uid);
  const updatedUser = updateUserDocArgsSchema.parse({
    ...args,
  });
  await updateDoc(userRef, updatedUser);
}

export async function validateAddresses(
  addresses: AddressDocument[]
): Promise<ShipEngineValidateResponse[]> {
  const res = await httpsCallable<AddressDocument[], any>(
    functions,
    FirebaseCallable.validateAddress
  )(addresses);
  return res.data;
}

export function canChangeUserName(user: UserDocument) {
  // can only change a name once every 30 days
  if (user.username_changed) {
    const lastChange = new Date(user.username_changed ?? 0);
    return dayjs().diff(lastChange, 'days') < 30;
  }
  return true;
}

export async function getUserToken({
  uid,
  emailOrUsername,
}: {
  uid?: string;
  emailOrUsername: string;
}) {
  if (!uid) {
    const q = query(userRef, where('email', '==', emailOrUsername), limit(1));
    const q2 = query(
      userRef,
      where('username', '==', emailOrUsername),
      limit(1)
    );
    const [docSnap, docSnap2] = await Promise.all([getDocs(q), getDocs(q2)]);
    const foundDoc = !docSnap.empty
      ? docSnap
      : !docSnap2.empty
      ? docSnap2
      : null;
    if (!foundDoc) return null;
    uid = foundDoc.docs[0].id;
  }
  const { data } = await httpsCallable<{ uid: string }, { token: string }>(
    functions,
    FirebaseCallable.impersonateUser
  )({ uid });
  if (data.token) {
    Cookies.set('impersonated', 'true');
  }
  return data.token;
}

interface UpdateAuthUserRequest {
  uid: string;
  emailVerified?: boolean;
  disabled?: boolean;
  email?: string;
  phoneNumber?: string | null;
  password?: string;
}

export async function updateAuthUser({
  uid,
  emailVerified,
  disabled,
  email,
  phoneNumber,
  password,
}: UpdateAuthUserRequest) {
  return httpsCallable<UpdateAuthUserRequest, null>(
    functions,
    FirebaseCallable.updateAuthUser
  )({
    uid,
    ...(emailVerified !== undefined && { emailVerified }),
    ...(disabled !== undefined && { disabled }),
    ...(email && { email }),
    ...(phoneNumber !== undefined && { phoneNumber }),
    ...(password && { password }),
  });
}

export function generateUsername(name: string | null): string {
  // generate a username (last name + first initial of first name)
  if (!name) return 'user' + Math.floor(Math.random() * 1000000);
  const [firstName, lastName] = name.split(' ');
  return slugify(
    `${lastName}${firstName[0]}-${Math.floor(Math.random() * 1000000)}}`
  );
}

export function getValidUserDocument(
  uid: string,
  email: string,
  form: {
    firstName: string;
    lastName: string;
    opted_out: boolean;
  },
  attribution: UserAttribution | null
): UserDocument {
  const data: Partial<UserDocument> = {
    addresses: [],
    bikes: [],
    name: `${form.firstName} ${form.lastName}`,
    first: form.firstName,
    last: form.lastName,
    phone: '',
    country: {
      code: 'US',
      name: 'United States',
      currency: 'USD',
    },
    account_activated: false,
    badge_counts: { quick_replies: 0, fast_shipper: 0, as_described: 0 },
    banned: false,
    brands: [],
    created: Date.now(),
    email,
    favorites: [],
    flag_count: 0,
    followers: 0,
    following: [],
    from_web: true,
    hide_status: false,
    is_verified: false,
    top_seller: false,
    on_vacation: false,
    // last_active: Date.now(),
    opted_out: form.opted_out,
    photo:
      'https://ui-avatars.com/api/?background=random&length=2&name=U&size=256',
    rating: 0,
    reviews: 0,
    seller_type: 'individual',
    uid,
    username: `user_${uid.slice(0, 10)}`,
    username_slug: `user_${uid.slice(0, 10)}`,
    username_changed: 0,
    notifications: notificationSettings,
    platforms: ['web'],
  };
  if (attribution) {
    data.attribution = attribution;
  }
  const docData = userSchema.parse(data);
  return docData;
}

export async function getUserAddresses(
  user: UserDocument,
  realUserUid?: string
): Promise<AddressDocument[]> {
  if (user.uid === process.env.NEXT_PUBLIC_SUPPORT_ID && realUserUid) {
    const realUser = await getUserDocument({ uid: realUserUid });
    return realUser?.addresses ?? [];
  }
  return user.addresses ?? [];
}

export async function getUniqueSlug(
  username: string,
  uid?: string
): Promise<string> {
  const existingUser = await getUserByUsernameSlugFromTypesense(
    slugify(username)
  );
  if (existingUser) {
    const str = uid ? uid.slice(0, 5) : Math.floor(Math.random() * 1000000);
    return slugify(`${username}-${str}`);
  }
  return slugify(username);
}

export function addUserBike(uid: string, bike: UserBike) {
  const userRef = doc(db, 'users', uid);
  return updateDoc(userRef, { bikes: arrayUnion({ ...bike }) });
}

export function editUserBike(
  userDoc: UserDocument,
  bike: UserBike,
  index: number
) {
  const userRef = doc(db, 'users', userDoc.uid);
  const bikes = userDoc.bikes ?? [];
  // const index = bikes.findIndex((b) => {
  //   console.log(b, 'bbbb');
  //   console.log(bike, 'bike');
  //   b.year === bike.year &&
  //     b.make === bike.make &&
  //     b.model === bike.model &&
  //     b.imageUrl === bike.imageUrl;
  // });

  //if (index === -1) throw new Error('Bike not found');
  bikes[index] = bike;
  return updateDoc(userRef, { bikes });
}

export function removeUserBike(uid: string, bike: Bike) {
  const userRef = doc(db, 'users', uid);
  return updateDoc(userRef, { bikes: arrayRemove({ ...bike }) });
}

export function addUserSizes(uid: string, sizes: UserSizes) {
  const userRef = doc(db, 'users', uid);
  return updateDoc(userRef, { sizes });
}

export function updateUserFavoriteBrands(
  userDoc: UserDocument,
  brands: Array<string>
) {
  const userRef = doc(db, 'users', userDoc.uid);
  return updateDoc(userRef, { brands });
}

export async function getSellersToFollow() {
  const nQuery = await namedQuery(db, 'featured-sellers-query');
  const seller1NQuery = await namedQuery(db, 'seller1-products-query');
  const seller2NQuery = await namedQuery(db, 'seller2-products-query');
  const seller3NQuery = await namedQuery(db, 'seller3-products-query');
  if (nQuery && seller1NQuery && seller2NQuery && seller3NQuery) {
    const sellers = await getDocsFromCache(nQuery);
    const seller1Products = await getDocsFromCache(seller1NQuery);
    const seller2Products = await getDocsFromCache(seller2NQuery);
    const seller3Products = await getDocsFromCache(seller3NQuery);
    const seller1 = cleanUser(sellers.docs[0].data() as UserDocument);
    const seller2 = cleanUser(sellers.docs[1].data() as UserDocument);
    const seller3 = cleanUser(sellers.docs[2].data() as UserDocument);
    const usersWithProducts = [
      {
        ...seller1,
        products: seller1Products.docs.map((d) =>
          cleanLocation(d.data() as ProductDocument)
        ),
      },
      {
        ...seller2,
        products: seller2Products.docs.map((d) =>
          cleanLocation(d.data() as ProductDocument)
        ),
      },
      {
        ...seller3,
        products: seller3Products.docs.map((d) =>
          cleanLocation(d.data() as ProductDocument)
        ),
      },
    ];
    return usersWithProducts;
  }
  const userRef = doc(db, 'other', 'sellers_to_follow');
  const docSnap = await getDoc(userRef);
  const data = docSnap.data();
  if (!data) return [];
  const users = await getUsersByIdsFromTypesense(data.uids);
  const productsPromises = users.map((u) => getProductsBySellerId(u.uid, 3));
  const products = await Promise.all(productsPromises);
  const usersWithProducts = users.map((u, i) => ({
    ...u, // user is already cleaned
    products: products[i],
  }));
  return usersWithProducts;
}

export type NexusState = {
  country: string;
  country_code: string;
  region: string;
  region_code: string;
};

export async function getNexusStates(): Promise<NexusState[]> {
  const ref = collection(db, 'nexus_states');
  const q = query(ref);
  const docSnap = await getDocs(q);
  return docSnap.docs.map((d) => d.data()) as NexusState[];
}

export function addFollowers(uid: string, following: string) {
  const userRef = doc(db, 'users', uid);
  return updateDoc(userRef, { following: arrayUnion(following) });
}

export function removeFollower(uid: string, following: string) {
  const userRef = doc(db, 'users', uid);
  return updateDoc(userRef, { following: arrayRemove(following) });
}

export function updateFollower(
  userDoc: UserDocument,
  following: string,
  index: number
) {
  const userRef = doc(db, 'users', userDoc.uid);
  const followingData = userDoc.following ?? [];
  followingData[index] = following;
  return updateDoc(userRef, { following: followingData });
}

export async function getUserSearchHistory(uid?: string): Promise<string[]> {
  if (!uid) return [];
  const userRef = doc(db, 'user_searches', uid);
  const docSnap = await getDoc(userRef);
  return docSnap.data()?.queries ?? [];
}

export async function setUserSearchHistory(uid: string, queries: string[]) {
  const userRef = doc(db, 'user_searches', uid);
  return updateDoc(userRef, { queries });
}

export async function updateSellerType(uid: string, type: SellerType) {
  try {
    await httpsCallable<{ uid: string; type: SellerType }, void>(
      functions,
      FirebaseCallable.updateSellerType
    )({ uid, type });
  } catch (e) {
    logError(e);
  }
}

export async function addNewSellerNote(uid: string, note: string) {
  const newNote = {
    text: note,
    created: Date.now(),
  };
  const userRef = doc(db, 'users', uid);
  return updateDoc(userRef, { notes: arrayUnion(newNote) });
}

export async function deleteSellerNote(
  uid: string,
  note: {
    text: string;
    created: number;
  }
) {
  const userRef = doc(db, 'users', uid);
  return updateDoc(userRef, { notes: arrayRemove(note) });
}

export function cleanUser(user: UserDocument): PublicUserDocument {
  return {
    id: user.uid ?? '',
    uid: user.uid ?? '',
    title: user.username ?? '',
    username: user.username ?? '',
    username_slug: user.username_slug ?? '',
    photo: user.photo ?? '',
    is_verified: user.is_verified ?? false,
    top_seller: user.top_seller ?? false,
    rating: user.rating ?? 0,
    reviews: user.reviews ?? 0,
    num_sold: user.metrics?.num_sold ?? 0,
    num_refunded: user.metrics?.num_refunded ?? 0,
    followers: user.followers ?? 0,
    seller_type: user.seller_type ?? 'individual',
    hide_status: user.hide_status ?? false,
    badge_counts: user.badge_counts ?? {
      quick_replies: 0,
      fast_shipper: 0,
      as_described: 0,
    },
    country: user.country ?? {
      code: 'US',
      currency: 'USD',
      name: 'United States',
    },
    created: user.created ?? Date.now(),
    bio: user.bio ?? '',
  };
}

export async function getReportedUsers() {
  const colRef = collection(db, 'reports_users');
  const q = query(colRef, limit(10));
  const docs = await getDocs(q);
  const res: Map<string, any> = new Map();
  docs.docs.forEach((d) => {
    res.set(d.id, d.data());
  });
  const users = await getUsersByIds(Array.from(res.keys()));
  const usersWithReports = users.map((u) => ({
    ...u,
    reports: res.get(u.uid),
  }));
  return usersWithReports;
}

export async function getSellersFromProducts(products: ProductDocument[]) {
  if (!products.length) return {};
  const seller_ids = [...new Set(products.map((r) => r.seller_id))];
  const filter_by = `uid:[${seller_ids.join(', ')}]`;
  const results = await client.collections('sellers').documents().search({
    query_by: 'username',
    q: '*',
    filter_by,
    per_page: seller_ids.length,
  });
  const sellerResults =
    results.hits?.map((h) => h.document as PublicUserDocument) ?? [];
  const sellers: {
    [key: string]: PublicUserDocument;
  } = {};
  products.forEach((r) => {
    const seller = sellerResults.find((s: any) => s.uid === r.seller_id);
    if (seller) {
      sellers[r.id] = seller;
    }
  });
  return sellers;
}

export async function signOutUserCallable(uid: string) {
  return httpsCallable<{ uid: string }, { success: boolean }>(
    functions,
    'v2_signOutUser'
  )({ uid });
}

export async function updateUserPlatforms(uid: string) {
  await updateDoc(getRef(uid), {
    platforms: arrayUnion('web'),
  });
}

export async function updateRating(rating: number, seller: UserDocument) {
  const aggregate = Math.min(
    5,
    (seller.rating * seller.reviews + rating) / (seller.reviews + 1)
  );
  return updateDoc(getRef(seller.uid), {
    reviews: increment(1),
    rating: Number(aggregate.toFixed(2)),
  });
}

export async function unlinkProviderCallable({
  uid,
  provider,
}: {
  uid: string;
  provider: string;
}) {
  await httpsCallable<{ uid: string; provider: string }, void>(
    functions,
    'v2_unlinkProvider'
  )({ uid, provider });
}

export async function deleteAccount({ user }: { user: User }) {
  await deleteUser(user);
}

export async function getPublicUserDoc(args: {
  uid?: string;
  username_slug?: string;
  username?: string;
  noCache?: boolean;
}) {
  if (!args.uid && !args.username_slug && !args.username) return null;
  if (args.uid) {
    const cdnUrl = process.env.NEXT_PUBLIC_IS_STAGING
      ? 'https://mxlocker-staging.b-cdn.net'
      : 'https://cdn.mxlocker.com';

    const url = new URL(`/${args.uid}/profile.json`, cdnUrl);
    if (args.noCache) url.searchParams.set('t', Date.now().toString());
    try {
      const res = await fetch(url);
      if (res.ok) {
        const data = await res.json();
        return data as PublicUserDocument;
      }
    } catch (e) {
      logError(e);
    }
  }
  try {
    const { data } = await httpsCallable<
      { uid?: string; username_slug?: string; username?: string },
      PublicUserDocument
    >(
      functions,
      'v2_getPublicUserDoc'
    )(args);
    return data;
  } catch (e) {
    logError(e);
  }
  return null;
}

export const getActivityVector = async (uid: string) => {
  try {
    const typesenseIndex = client
      .collections<UserProfile>('user_profiles_alias')
      .documents();
    const user_profile_res = await typesenseIndex.search({
      q: '*',
      filter_by: `uid:${uid}`,
      query_by: 'uid',
    });
    const user_profile = user_profile_res.hits?.[0]?.document;
    const activity_profile = user_profile?.activity_profile;
    const activity_vector_query =
      activity_profile && `embedding:([${activity_profile}], k:10000)`;
    return activity_vector_query ?? null;
  } catch (e) {
    logError(e, `getActivityVector ${uid}`);
  }
  return null;
};

export const checkPhoneNumber = async ({
  phoneNumber,
}: {
  phoneNumber: string;
}) => {
  return await httpsCallable<
    { phone: string },
    { is_valid: boolean; error: string }
  >(
    functions,
    'v2_verifyPhone'
  )({ phone: phoneNumber });
};

export const getMyFeed = async (userDoc: UserDocument) => {
  return await httpsCallable<
    { user: UserDocument; per_page?: number },
    {
      recent: ProductDocument[];
      gear: ProductDocument[];
      parts: { bike: UserBike | null; products: ProductDocument[] }[];
    }
  >(
    functions,
    'v2_getMyFeedPage'
  )({ user: userDoc, per_page: 10 });
};

export async function addToInteractions(
  uid: string,
  key: keyof InteractionDoc,
  productIds: string[]
) {
  if (!productIds?.length) return;
  try {
    const ref = doc(db, 'interactions', uid);
    await setDoc(ref, { [key]: arrayUnion(...productIds) }, { merge: true });
  } catch (e) {
    logError(e);
  }
}

export async function addToDiscoveredProducts(
  uid: string,
  productIds: string[]
) {
  if (!productIds?.length) return;
  try {
    await httpsCallable<{ uid: string; product_ids: string[] }, void>(
      functions,
      'v2_productSeen'
    )({ uid, product_ids: productIds });
  } catch (e) {
    logError(e);
  }
}
export const getDefaultAddress = (userDoc?: UserDocument | null) => {
  if (!userDoc?.addresses?.length) return;
  return (
    userDoc.addresses?.find((a) => a.is_default) ??
    userDoc.addresses?.find((a) => a.is_shop_location) ??
    userDoc.addresses?.[0]
  );
};
