import {
  arrayRemove,
  arrayUnion,
  collection,
  CollectionReference,
  doc,
  getCountFromServer,
  limit,
  updateDoc,
} from '@firebase/firestore';
import {
  OrderDocument,
  OrderItemDocument,
  OrderNoteType,
  OrderStates,
  OrderStateType,
  TransferObject,
} from '@models/order';
import { db, FirebaseCallable, functions, httpsCallable } from '@util/firebase';
import { logError } from '@util/logError';
import { CarrierCode } from '@util/maps/carriers';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {
  deleteField,
  DocumentData,
  getDoc,
  getDocs,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import { ProductDocument } from 'models/product';
import { z } from 'zod';
import { updateProductByKey } from '../products';
import { getUserByUsername } from '../users';
import { SchemaValues } from '@c/modals/ExportReportModal';
dayjs.extend(isBetween);

export const ordersRef = collection(
  db,
  'orders'
) as CollectionReference<OrderDocument>;

const getRef = (id: string) => doc<OrderDocument, DocumentData>(ordersRef, id);

export const getOrderById = async (
  id: string
): Promise<OrderDocument | null> => {
  const snapshot = await getDoc(getRef(id));
  try {
    return snapshot.data() as OrderDocument;
  } catch (e) {
    logError(e);
    return null;
  }
};

async function updateOrder(order: OrderDocument) {
  await updateDoc(getRef(order.id), {
    sellers: order.sellers,
    total_state: order.total_state,
    seller_arr: order.seller_arr || [],
  });
}

export const addReviewedSeller = async (orderId: string, sellerId: string) => {
  return updateDoc(getRef(orderId), {
    reviewed_sellers: arrayUnion(sellerId),
  });
};

export const updateOrderByKey = async (
  orderId: string,
  key: keyof OrderDocument,
  value: any
) => {
  await updateDoc(getRef(orderId), {
    [key]: value,
  });
};

export const updateOrderItemState = async (
  order: OrderDocument,
  sellerId: string,
  itemId: string,
  state: number
) => {
  const sellers = order.sellers;
  const index =
    sellers?.[sellerId].findIndex((item) => item.id === itemId) ?? -1;
  if (index >= 0 && sellers) {
    sellers[sellerId][index].state = state;
    let allComplete = true;
    const states = new Set<number>();
    Object.values(sellers).forEach((items) =>
      items.forEach((item) => {
        states.add(item.state);
        if (item.state < 3) allComplete = false;
      })
    );

    let total_state = order.total_state;
    if (states.has(6) || states.has(7)) {
      total_state = 3;
    } else if (allComplete) {
      total_state = 2;
    } else if (states.has(2)) {
      total_state = 1;
    } else {
      total_state = 0;
    }

    await updateDoc(getRef(order.id), {
      sellers,
      total_state,
    });
  }
};

export const getOrderByBuyerId = async (seller_id: string, uid: string) => {
  const q = query(
    ordersRef,
    where('seller_arr', 'array-contains', seller_id),
    where('buyer_id', '==', uid)
  );
  const snapshot = await getDocs(q);
  const data = snapshot.docs.map((doc) => {
    return doc.data();
  });

  return data;
};

export const getOrderByOrderNum = async (
  order_num: number | null,
  variant: 'buyer' | 'seller' | 'admin' = 'buyer',
  uid?: string | null
): Promise<OrderDocument | null> => {
  if (!order_num || !uid) return null;
  const constraints = [];
  if (variant === 'buyer') {
    constraints.push(where('buyer_id', '==', uid));
  } else if (variant === 'seller') {
    constraints.push(where('seller_arr', 'array-contains', uid));
  }
  constraints.push(where('order_num', '==', order_num));
  const q = query(ordersRef, ...constraints, limit(1));

  const snapshot = await getDocs(q);
  const data = snapshot.docs.map((doc) => {
    return doc.data();
  });
  if (data) {
    return data[0];
  }
  return null;
};

export const getAllBuyerOrders = async ({
  uid,
  isAdmin,
  page,
  order_status,
  search,
  limit: l = 10,
}: {
  uid: string;
  isAdmin?: boolean;
  page: number;
  order_status: OrderStateType | 'all';
  search: string;
  limit?: number;
}) => {
  if (search && search.length === 5 && !isNaN(Number(search))) {
    // get the order by order number
    const order = await getOrderByOrderNum(
      Number(search),
      isAdmin ? 'admin' : 'buyer',
      uid
    );
    if (order && (order.buyer_id === uid || isAdmin)) {
      return {
        results: [order],
        pageInfo: {
          total: 1,
          page: 1,
          totalPages: 1,
        },
      };
    } else {
      return {
        results: [],
        pageInfo: {
          total: 0,
          page: 1,
          totalPages: 1,
        },
      };
    }
  }

  // If a order status has not been specified (is equal to "all"), set to null
  const shipment_state =
    order_status !== 'all' ? OrderStates.indexOf(order_status) : null;

  // Get total counts
  const noLimitQ = query(
    ordersRef,
    where('buyer_id', '==', uid),
    ...(shipment_state !== null
      ? [where('states', 'array-contains', shipment_state)]
      : []),
    orderBy('created', 'desc')
  );
  const noLimitAdminQ = query(
    ordersRef,
    ...(shipment_state !== null
      ? [where('states', 'array-contains', shipment_state)]
      : [where('total_state', 'in', [0, 1])]),
    orderBy('created', 'desc')
  );
  const count = await getCountFromServer(isAdmin ? noLimitAdminQ : noLimitQ);
  const totalPages = Math.ceil(count.data().count / l);

  // handle pagination
  let lastVisible = null;
  if (page > 1) {
    const q = query(
      ordersRef,
      where('buyer_id', '==', uid),
      ...(shipment_state !== null
        ? [where('states', 'array-contains', shipment_state)]
        : []),
      orderBy('created', 'desc'),
      limit((page - 1) * l)
    );
    const adminQ = query(
      ordersRef,
      ...(shipment_state !== null
        ? [where('states', 'array-contains', shipment_state)]
        : [where('total_state', 'in', [0, 1])]),
      orderBy('created', 'desc'),
      limit((page - 1) * l)
    );
    const snap = await getDocs(isAdmin ? adminQ : q);
    lastVisible = snap.docs[snap.docs.length - 1];
  }

  const q = query(
    ordersRef,
    where('buyer_id', '==', uid),
    ...(shipment_state !== null
      ? [where('states', 'array-contains', shipment_state)]
      : []),
    orderBy('created', 'desc'),
    limit(l),
    ...(lastVisible ? [startAfter(lastVisible)] : [])
  );
  const adminQ = query(
    ordersRef,
    ...(shipment_state !== null
      ? [where('states', 'array-contains', shipment_state)]
      : [where('total_state', 'in', [0, 1, 3])]),
    orderBy('created', 'desc'),
    limit(l),
    ...(lastVisible ? [startAfter(lastVisible)] : [])
  );
  const snapshot = await getDocs(isAdmin ? adminQ : q);
  const data = snapshot.docs.map((doc) => {
    return doc.data();
  });

  if (typeof data === 'undefined') {
    return {
      results: [],
      pageInfo: {
        total: 0,
        page: 1,
        totalPages: 1,
      },
    };
  }
  return {
    results: data,
    pageInfo: {
      total: count.data().count,
      page,
      totalPages,
    },
  };
};

// interface GetOrdersArgs {
//   uid: string;
//   isAdmin?: boolean;
//   adminUid?: string | null; // uid to get orders for as admin
//   lastKey?: string | null;
//   orderStatus: OrderStateType | 'all';
// }

export const getOrderStateNumberFromStatus = (status: string) => {
  switch (status) {
    case 'orderPlaced':
      return 0;
    case 'delivered':
      return 1;
    case 'complete':
      return 2;
    case 'onHold':
      return 3;
    case 'returnRequested':
      return 4;
  }
};

// THIS FUNCTION COULD USE A REWORK LOGIC IS KINDA MESSY
export const getAllSellerOrders = async ({
  uid,
  page,
  orderState,
  search,
  searchBy = 'orderNumber',
}: {
  uid: string;
  page: number;
  orderState: OrderStateType | 'all' | 'other' | 'unpaid';
  search: string;
  searchBy?: 'orderNumber' | 'username';
}) => {
  // NOTE: fixes firestore access denied errors when uid is empty on initial page load
  if (!uid) {
    return {
      results: [],
      pageInfo: {
        total: 0,
        page: 1,
        totalPages: 1,
      },
    };
  }

  if (
    search &&
    ((searchBy === 'orderNumber' &&
      search.length === 5 &&
      !isNaN(Number(search))) ||
      searchBy === 'username')
  ) {
    // get the order by order number
    let orders: OrderDocument[] = [];
    if (searchBy === 'orderNumber') {
      const order = await getOrderByOrderNum(Number(search), 'seller', uid);
      if (order) {
        orders = [order];
      }
    } else {
      const user = await getUserByUsername(search);

      if (user) {
        const fetchedOrders = await getOrderByBuyerId(user.uid, uid);

        orders = fetchedOrders;
      }
    }
    if (orders.length && orders.every((o) => o.seller_arr.includes(uid))) {
      return {
        results: orders,
        pageInfo: {
          total: 1,
          page: 1,
          totalPages: 1,
        },
      };
    } else {
      const filteredOrders = orders.filter((o) => o.seller_arr.includes(uid));
      return {
        results: filteredOrders,
        pageInfo: {
          total: filteredOrders.length,
          page: 1,
          totalPages: 1,
        },
      };
    }
  }
  // 0-Awaiting Shipment, 1-In Transit, 2-Delivered, 3-Complete, 4-Cancelled (Seller Couldn't fulfill Order), 5-Refunded (Buyer asks, we issue refund), 6-Delivery Error, 7-On Hold
  const stateIndex =
    orderState !== 'all' && orderState !== 'other' && orderState !== 'unpaid'
      ? OrderStates.indexOf(orderState)
      : null;

  // 0-Order Placed 1-Delivered 2-Complete, 3- On Hold, 4- Return Requested
  let totalState: number | null = null;
  if (stateIndex !== null) {
    if (stateIndex < 2) totalState = 0;
    else if (stateIndex === 2) totalState = 1;
    else if (stateIndex === 3) totalState = 2;
    else if (stateIndex === 7) totalState = 3;
    else if (stateIndex >= 4 && stateIndex < 7) totalState = 4;
  } else if (orderState === 'other') {
    totalState = 3;
  } else if (orderState === 'unpaid') {
    totalState = 0;
  }
  // Get total counts
  const noLimitQ = query(
    ordersRef,
    where('seller_arr', 'array-contains', uid),
    ...(orderState !== 'all' ? [where('total_state', '==', totalState)] : []),
    orderBy('created', 'desc')
  );

  const count = await getCountFromServer(noLimitQ);
  const totalPages = Math.ceil(count.data().count / 10);

  // handle pagination
  let lastVisible = null;
  if (
    page > 1 &&
    orderState !== 'Awaiting Shipment' &&
    orderState !== 'In Transit' &&
    orderState !== 'Delivered' &&
    orderState !== 'unpaid'
  ) {
    const q = query(
      ordersRef,
      where('seller_arr', 'array-contains', uid),
      page < totalPages / 2 ? orderBy('created', 'desc') : orderBy('created'), // If we're going to a page closer to the end, its more efficient to fetch from the back
      page < totalPages / 2
        ? limit((page - 1) * 10)
        : limit((totalPages - page + 1) * 10) // If we're going to a page closer to the end, its more efficient to fetch from the back
    );

    const snap = await getDocs(q);
    lastVisible = snap.docs[snap.docs.length - 1];
  }

  const q = query(
    ordersRef,
    where('seller_arr', 'array-contains', uid),
    ...(orderState !== 'all' &&
    orderState !== 'Awaiting Shipment' &&
    orderState !== 'In Transit' &&
    orderState !== 'Delivered' &&
    orderState !== 'unpaid'
      ? [where('total_state', '==', totalState)]
      : []),
    orderBy('created', 'desc'),
    ...(orderState !== 'Awaiting Shipment' &&
    orderState !== 'In Transit' &&
    orderState !== 'Delivered' &&
    orderState !== 'unpaid'
      ? [limit(10)]
      : []),
    ...(lastVisible ? [startAfter(lastVisible)] : [])
  );
  const snapshot = await getDocs(q);
  const data = snapshot.docs.map((doc) => {
    return doc.data();
  });

  if (typeof data === 'undefined') {
    return {
      results: [],
      pageInfo: {
        total: 0,
        page: 1,
        totalPages: 1,
      },
    };
  }
  return {
    results: data,
    pageInfo: {
      total: count.data().count,
      page,
      totalPages,
    },
  };
};

export const getSellerOrdersByState = async (
  uid: string,
  state: number
): Promise<{ results: OrderDocument[] }> => {
  // NOTE: fixes firestore access denied errors when uid is empty on initial page load
  if (!uid) {
    return { results: [] };
  }
  const q = query(
    ordersRef,
    where('seller_arr', 'array-contains', uid),
    where('total_state', '==', state),
    orderBy('created', 'desc')
  );
  const snap = await getDocs(q);
  const results = snap.docs.map((doc) => doc.data());
  return { results };
};

export async function getPendingOrders(seller_id?: string) {
  // NOTE: fixes firestore access denied errors when uid is empty on initial page load
  if (!seller_id) {
    return [];
  }
  const q = query(
    ordersRef,
    where('seller_arr', 'array-contains', seller_id),
    where('total_state', 'in', [0, 1, 3])
  );
  const snap = await getDocs(q);
  const results = snap.docs.map((doc) => doc.data());
  return results ?? [];
}

export function getOrderId(): string {
  const newDocRef = doc(ordersRef);
  const id = newDocRef.id;
  return id;
}

export async function getOrderNum(): Promise<number> {
  try {
    const orderCount = await getDoc(getRef('count'));
    const count: number | undefined = orderCount.get('count');
    return (count ?? 10000) + 1;
  } catch (e) {
    logError(e);
  }
  return 10000;
}

export async function getOrderNumForConfirmation(id: string) {
  try {
    const order = await getDoc(getRef(id));
    const orderNum: number = order.get('order_num');
    if (orderNum) return orderNum;
  } catch (_e) {
    // doesn't exist yet
  }
  return getOrderNum();
}

export async function getOrderByPaymentId(payment_id: string) {
  const q = query(ordersRef, where('payment_id', '==', payment_id), limit(1));
  const snap = await getDocs(q);
  return snap.empty ? null : snap.docs[0].data();
}

export async function getOrderAdmin(orderIdentifier: string) {
  const c = orderIdentifier.startsWith('ch_') // stripe charge
    ? where('payment_id', '==', orderIdentifier)
    : orderIdentifier.length === 5 // order number
    ? where('order_num', '==', Number(orderIdentifier))
    : where('tns', 'array-contains', orderIdentifier); // tracking number
  const q = query(ordersRef, c, limit(1));
  const snap = await getDocs(q);
  return snap.empty ? null : snap.docs[0].data();
}

export const markAsDelivered = (order: OrderDocument) => {
  return updateDoc(getRef(order.id), {
    sellers: order.sellers,
    total_state: 1,
    tracking_error: deleteField(),
  });
};

// product_ids are the items the buyer is marking as received
export async function markAsReceived(orderId: string, product_ids: string[]) {
  const res = await httpsCallable<
    { id: string; product_ids: string[] },
    { data: 'success' }
  >(
    functions,
    FirebaseCallable.markAsReceived
  )({ id: orderId, product_ids });
  return res.data;
}

export async function addNewNote(
  orderId: string,
  note: string,
  uid?: string
): Promise<void> {
  // add a new note to the order
  const newNote: OrderNoteType = {
    text: note,
    created: Date.now(),
    ...(uid && { uid }),
  };
  await updateDoc(doc(ordersRef, orderId), {
    notes: arrayUnion(newNote),
  });
}

export async function deleteNote(
  orderId: string,
  note: OrderNoteType
): Promise<void> {
  // delete a note from the order
  await updateDoc(doc(ordersRef, orderId), {
    notes: arrayRemove(note),
  });
}

export async function updateSellers(order: OrderDocument) {
  await updateDoc(doc(ordersRef, order.id), {
    sellers: order.sellers,
  });
}

export async function updateOrderDroppedOff(
  order: OrderDocument,
  item: OrderItemDocument
) {
  const sellers = order.sellers;
  const seller = sellers[item.seller_id];
  if (!seller) return;
  seller.forEach((i) => {
    if (i.id === item.id) {
      i.dropped_off = true;
    }
  });
  await updateDoc(doc(ordersRef, order.id), {
    sellers,
  });
}

export async function getAverageTimeToShip(uid: string) {
  // TODO: Move this to backend and set on userDoc.metrics
  // get completed orders
  const q = query(
    ordersRef,
    where('seller_arr', 'array-contains', uid),
    where('total_state', 'in', [1, 2])
  );
  const snap = await getDocs(q);
  const orders = snap.docs.map((doc) => doc.data());
  if (!orders.length) {
    return 0;
  }
  let total = 0;
  let count = 0;
  orders.forEach((order) => {
    const items = order.sellers?.[uid];
    const totalTimeToShip = items?.reduce((acc, item) => {
      if (item.time_to_ship) {
        return acc + item.time_to_ship;
      }
      return acc;
    }, 0);

    if (totalTimeToShip && items?.length) {
      total += totalTimeToShip;
      count += items.length;
    }
  });
  if (count === 0) return 0;
  return (total / count / 1000 / 60 / 60 / 24).toFixed(2);
}

interface StartTrackingArgs {
  carrier: CarrierCode;
  trackingNo: string;
  orderId: string;
  sellerId: string;
  productId: string;
}

interface StartTrackingError {
  status: 'error';
  message: string;
}

interface StartTrackingSuccess {
  status: 'success';
}

export type StartTrackingResponse = StartTrackingError | StartTrackingSuccess;

export async function startTracking(
  data: StartTrackingArgs
): Promise<StartTrackingResponse> {
  data.trackingNo = data.trackingNo.trim();
  // call the startTracking firebase function
  try {
    await httpsCallable<{}, StartTrackingArgs>(
      functions,
      FirebaseCallable.startTracking
    )(data);
    return {
      status: 'success',
    };
  } catch (e) {
    logError(e);
    if (
      !!e &&
      typeof e === 'object' &&
      'message' in e &&
      typeof e.message === 'string'
    ) {
      return {
        status: 'error',
        message: e.message,
      };
    }
    return {
      status: 'error',
      message: 'Unknown error',
    };
  }
}

type ShopOverviewResponse = {
  data: {
    week: {
      chart: [number, number][];
      total: number;
      profit: number; // used for roles.reseller
    };
    month: {
      chart: [number, number][];
      total: number;
      profit: number;
    };
    lmonth: {
      chart: [number, number][];
      total: number;
      profit: number;
    };
    year: {
      chart: [number, number][];
      total: number;
      profit: number;
    };
    lyear: {
      chart: [number, number][];
      total: number;
      profit: number;
    };
    all: {
      chart: [number, number][];
      total: number;
      profit: number;
    };
  };
  recent_orders: OrderDocument[];
  pending_orders: number;
  completed_orders: number;
  available_balance: number;
  actual_pending: number;
  currency: string;
  cash_exposure: number;
};

export async function getShopOverview(uid?: string) {
  const res = await httpsCallable<{ uid?: string }, ShopOverviewResponse>(
    functions,
    FirebaseCallable.getShopOverview
  )({ uid });
  return res.data;
}

export const reasons = [
  z.literal('requested_by_customer'),
  z.literal('duplicate'),
  z.literal('fraudulent'),
  z.literal('requested_by_seller'),
  z.literal('product_return'),
  z.literal('cancelled_order'),
  z.literal('item_no_longer_in_stock'),
  z.literal('shipping_complications'),
  z.literal('out_of_town'),
  z.literal('not_shipped_in_required_time_period'),
] as const;
export const reasonSchema = z.union(reasons);
export type Reason = z.infer<typeof reasonSchema>;

export const labels = [
  'Requested by customer',
  'Duplicate',
  'Fraudulent',
  'Requested by seller',
];

export const ALL_REASONS = reasons.map((reason) => reason.value);

type refundOrderArgs = {
  reason: Reason;
  product_ids: string[];
  partial_amount?: number;
  order: OrderDocument;
};

export async function refundOrder(args: refundOrderArgs) {
  const res = await httpsCallable<refundOrderArgs, {}>(
    functions,
    FirebaseCallable.refundOrder
  )(args);
  return res.data;
}

interface CalculateEaringsArgs {
  item: OrderItemDocument;
  item_count: number;
  buyerState?: string;
  sellerTaxStates?: string[];
  fee?: number;
  created: number;
  coupon?: OrderDocument['coupon'];
}
export const calculateEarnings = ({
  item,
  item_count,
  buyerState = '',
  sellerTaxStates = [],
  fee = 0.1,
  created,
  coupon,
}: CalculateEaringsArgs) => {
  // legacy fee for orders before 2024-03-20 PDT
  const effective_fee =
    new Date(created) < new Date(1710918000000) && fee === 0.1
      ? 0.08
      : item.fee_override ?? fee;
  const seller_coupon =
    coupon?.off && coupon.seller_id === item.seller_id
      ? coupon.off / (item_count || 1)
      : 0;
  const subtotal = item.product_cost * item.qty - seller_coupon;
  const total =
    subtotal + (item.buyer_shipping_cost ?? 0) + (item.product_tax ?? 0);
  let processing = total * 0.03;
  const shipping =
    !item.rate_id && item.buyer_shipping_cost ? item.buyer_shipping_cost : 0;
  const label_cost = item.rate_id ? item.buyer_shipping_cost ?? 0 : 0;
  const refund_amount = item.refund_amount ?? 0;
  let commission = effective_fee * (shipping + subtotal - refund_amount);
  const sellerRemits = sellerTaxStates.includes(buyerState);
  const taxes = item.product_tax ?? 0;
  const ins_amount = item.insurance_amount ?? 0;
  const remittedTax = sellerRemits ? item.product_tax ?? 0 : 0;

  let net = 0;
  if (refund_amount >= subtotal + shipping) {
    net = total - refund_amount;
    processing = 0;
    commission = 0;
  } else {
    net =
      total -
      processing -
      commission -
      label_cost -
      taxes +
      remittedTax -
      ins_amount -
      refund_amount;
  }

  return [
    net,
    commission,
    taxes,
    processing,
    ins_amount,
    remittedTax,
    seller_coupon,
  ];
};

export async function transferSeller(
  order: OrderDocument,
  item: OrderItemDocument,
  oldSellerId: string
) {
  try {
    const sellerUsername = prompt(
      "Enter the seller's username you wish to transfer this item to"
    );
    if (sellerUsername && order) {
      const newSeller = await getUserByUsername(sellerUsername);
      if (!newSeller) return alert('User not found');
      if (!order.sellers?.[oldSellerId]) return alert('Old seller not found');
      //remove item from old seller
      order.sellers[oldSellerId] = order.sellers?.[oldSellerId].filter(
        (i) => i.id !== item.id
      );
      if (!order.sellers[oldSellerId].length) delete order.sellers[oldSellerId];
      //add item to new seller
      item.account_id = newSeller.account_id!;
      item.seller_id = newSeller.uid;
      if (order.sellers[newSeller.uid]?.length)
        order.sellers[newSeller.uid].push(item);
      else order.sellers[newSeller.uid] = [item];
      await updateProductByKey(item.id, 'seller_id', newSeller.uid);
      order.seller_arr = order.sellers ? Object.keys(order.sellers) : [];
      await updateOrder(order);
    }
  } catch (e) {
    alert('Error transferring seller');
    console.error(e);
  }
}

export async function getOrderItemsByProductID(productID: string) {
  const q = query(ordersRef, where('product_ids', 'array-contains', productID));
  const orders = await getDocs(q);

  if (!orders) {
    return null;
  }

  let collectedOrderItems = [] as (OrderItemDocument & {
    orderNum: number;
    orderId: string;
    soldDate: number;
  })[];

  // NOTE: ugh... O(n^3). should it matter??
  orders.forEach((order) => {
    const orderData = order.data();
    orderData.seller_arr.forEach((sellerID) => {
      // @ts-ignore: we are able to assume that all keys of orderData.sellers matches with orderData.seller_arr
      const orderItems = orderData.sellers[sellerID];
      orderItems.forEach((orderItem) => {
        if (orderItem.id === productID) {
          collectedOrderItems.push({
            ...orderItem,
            orderNum: orderData.order_num,
            orderId: orderData.id,
            soldDate: orderData.created,
          });
        }
      });
    });
  });

  return collectedOrderItems;
}

export async function getPastDueOrders() {
  // admin query to fetch all orders that have any items awaiting shipment 7 days after the order was placed
  const q = query(
    ordersRef,
    where('states', 'array-contains', 0),
    where('created', '<', Date.now() - 6 * 24 * 60 * 60 * 1000),
    orderBy('created', 'asc')
  );

  const docs = await getDocs(q);
  return docs.docs.map((doc) => doc.data() as OrderDocument);
}

/**
 * @deprecated Size of order res would be huge in a year or two
 */
export const getPendingReviewCount = async (uid?: string) => {
  // NOTE: fixes firestore access denied errors when uid is empty on initial page load
  if (!uid) {
    return 0;
  }
  // get all orders where uid == buyer_id and reviewed_sellers does not equal sellers_arr
  const q = query(
    ordersRef,
    where('buyer_id', '==', uid),
    where('total_state', 'in', [1, 2]),
    where('created', '>=', 1688011200000) // New launch date 6-29-2023
  );
  const snapshot = await getDocs(q);
  if (snapshot.empty) {
    return 0;
  }
  let count = 0;
  snapshot.docs.forEach((doc) => {
    const order = doc.data() as OrderDocument;
    const notReviewedSellers = order.seller_arr!.filter(
      (seller) => !order.reviewed_sellers?.includes(seller)
    );
    count += notReviewedSellers.length;
  });
  return count;
};

export async function getOrdersByTrackingError() {
  const q = query(
    ordersRef,
    where('tracking_error', '!=', null),
    orderBy('tracking_error', 'desc')
  );
  const snapshot = await getDocs(q);
  const data = snapshot.docs.map((doc) => {
    return doc.data();
  });
  return data;
}

export async function getManualTransfers() {
  try {
    const colRef = collection(db, 'manual_transfers');
    const q = query(
      colRef,
      where('status', '==', 'pending'),
      orderBy('created', 'asc')
    );
    const snapshot = await getDocs(q);
    const data = snapshot.docs.map((doc) => {
      return doc.data() as TransferObject;
    });
    return data;
  } catch (e) {
    logError(e);
  }
}
export async function getOrdersByLabelError() {
  const q = query(ordersRef, orderBy('label_error', 'desc'));
  const snapshot = await getDocs(q);
  const data = snapshot.docs.map((doc) => {
    return doc.data();
  });
  return data;
}

export async function exportPayouts(
  start: number,
  end: number,
  timezone: string,
  type: 'Balance' | 'Payouts'
) {
  return await httpsCallable<
    { start: number; end: number; timezone: string; is_balance: boolean },
    { data: string }
  >(
    functions,
    FirebaseCallable.exportPayouts
  )({
    start,
    end,
    timezone,
    is_balance: type === 'Balance',
  });
}

interface OrderExportRow {
  saleDate: string;
  orderNumber: number;
  itemTitle: string;
  buyerName: string;
  buyerAddress: string;
  grossSellingPrice: number;
  taxRemitted: number;
  mxLockerFee: number;
  processingFee: number;
}
export async function exportOrders(
  start: string,
  end: string,
  fields: Partial<SchemaValues>
): Promise<{ data: OrderExportRow[] }> {
  return await httpsCallable<
    { start: string; end: string; fields: Partial<SchemaValues> },
    OrderExportRow[]
  >(
    functions,
    FirebaseCallable.exportOrders
  )({ start, end, fields });
}

interface InitiateReturnArgs {
  order: OrderDocument;
  products: Pick<ProductDocument, 'title' | 'id'>[];
}
export async function initiateReturn(args: InitiateReturnArgs) {
  return await httpsCallable<InitiateReturnArgs, { chat_id: string }>(
    functions,
    FirebaseCallable.initiateReturn
  )(args);
}

export interface SurePost {
  created: number;
  items: {
    id: string;
    seller_id: string;
    tracking_number: string;
  }[];
  order_id: string;
  order_num: number;
}
export async function getSurePostOrders() {}
