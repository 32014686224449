import { UserDocument } from '@models/user';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { z } from 'zod';
import { addressSchema } from './address';
import {
  ApiResponsePaginated,
  ApiResponsePaginatedByRef,
  Bike,
  bikeSchema,
  countrySchema,
  genderSchema,
  makeSchema,
  rideTypes,
  sellerTypeSchema,
  sizeSchema,
  uidSchema,
} from './shared';
import { SearchResponseFacetCountSchema } from 'typesense/lib/Typesense/Documents';

export const userSortOptions = z.union([
  z.literal('rating'),
  z.literal('name'),
]);
export type UserSortOption = z.infer<typeof userSortOptions>;

export const productLimitOptions = z.union([
  z.literal(12),
  z.literal(24),
  z.literal(48),
]);
export type ProductLimitOption = z.infer<typeof productLimitOptions>;
const variationSchema = z
  .object({
    price: z.number(),
    size: z.union([z.string(), z.number()]),
    color: z.string().nullable().default(null).optional(),
    qty: z.number().default(1),
    is_number: z.boolean().default(false),
    sku: z.string().optional(),
  })
  .transform((x) => {
    if (x.size) x.is_number = !isNaN(x.size as any);
    return x;
  });

export const FollowingSchema = z.object({
  uid: z.string(),
});

export const SizeSchema = z.object({
  Helmet: z.string().optional(),
  Jersey: z.string().optional(),
  Pants: z.number().optional(),
  Boots: z.number().optional(),
});

export const BIKE_TYPES = [
  'Motocross',
  'Trail/Enduro',
  'Vintage',
  'Pit Bike',
] as const;

export const bikeTypeSlugToBikeType = {
  motocross: 'Motocross',
  trailenduro: 'Trail/Enduro',
  vintage: 'Vintage',
  'pit-bike': 'Pit Bike',
};
export const bikeTypes = z.union([
  z.literal('Motocross'),
  z.literal('Trail/Enduro'),
  z.literal('Vintage'),
  z.literal('Pit Bike'),
]);

export const conditionTypes = z.union([z.literal('New'), z.literal('Used')]);
export type Condition = z.infer<typeof conditionTypes>;

export const dateTimeSchema = z
  .number()
  .int()
  .gte(1000000000000)
  .lte(3000000000000);

export const dimensionsSchema = z.object({
  unit: z.literal('inch').default('inch'),
  length: z.number().gt(0),
  width: z.number().gt(0),
  height: z.number().gt(0),
});

export type Dimensions = z.infer<typeof dimensionsSchema>;

const weightSchema = z.object({
  value: z.number().gt(0),
  unit: z.literal('ounce'),
});
export type Weight = z.infer<typeof weightSchema>;

export const shipmentSchema = z.object({
  ship_from: addressSchema,
  package: z.object({
    weight: weightSchema,
    dimensions: dimensionsSchema,
    service_preference: z.array(z.string()).optional(), //  list of service codes
    label_preference: z
      .union([
        z.literal('label'),
        z.literal('qr_code'),
        z.literal('label_and_qr_code'),
      ])
      .optional(),
    insurance_provider: z
      .union([
        z.literal('carrier'),
        z.literal('shipsurance'),
        z.literal('third_party'),
        z.literal('mx_locker'),
        z.literal('none'),
      ])
      .default('none')
      .optional(),
    insured_value: z
      .object({ currency: z.literal('usd').default('usd'), amount: z.number() })
      .optional(),
  }),
});

export type Shipment = z.infer<typeof shipmentSchema>;

const imageSchema = z.object({
  thumb: z.string(),
  mime_type: z.string().optional(),
  download_url: z.string().optional(),
  full: z.string(),
});

export const productSchema = z.object({
  id: z.string().min(10),
  account_id: z.string().default(''), // seller's stripe account id
  auction_name: z.string().optional(),
  bike_package_ch: z.string().optional(), // the stripe charge purchasing a bike package
  bike_package: z.union([z.literal(1), z.literal(2), z.literal(3)]).optional(), // for bikes
  bought_by: z.array(z.string()).optional(),
  brand: z.string().default('N/A'),
  bumps: z.number().int().default(0),
  categories: z.array(z.string()).default([]),
  category: z.string(),
  category1: z.string().nullable().optional(),
  category2: z.string().trim().nullable().optional(),
  category3: z.string().nullable().optional(),
  cause: z.string().optional(),
  condition: conditionTypes,
  country_code: countrySchema,
  created: dateTimeSchema.default(Date.now()),
  curated_lists: z.array(z.string()).optional(),
  date_added: dateTimeSchema.optional(), // optional when deleted
  date_featured: dateTimeSchema.optional(), // time of bike package purchase
  date_updated: dateTimeSchema.optional().default(Date.now()),
  deleted: z.boolean().default(false).optional(), // only true if user deletes their account
  description_html: z.string().optional(),
  description: z.string().min(1, 'Description is required'),
  disclaimer: z.string().optional(),
  end_time: dateTimeSchema.optional(),
  engine: z.number().optional(),
  favorite_count: z.number().int().default(0),
  fee_override: z.number().optional(),
  fitting_bikes: z
    .object({
      bikes: z.array(bikeSchema).default([]),
      universal: z.boolean().default(false),
    })
    .optional(),
  floor_price: z.number().optional(), // smart pricing
  from_web: z.boolean().default(true),
  gender: genderSchema.optional(),
  has_variations: z.boolean().default(false),
  hash: z.string().default(''), // email hash
  images: z.array(imageSchema).min(1, 'At least one image is required'),
  // NOTE: inventory_ids and location_ids are specific to Shopify
  integration_info: z
    .object({
      type: z.string(),
      date_updated: dateTimeSchema,
      out_of_sync: z.boolean().default(false),
      remote_id: z.string(), // Maps to a Shopify product ID
      inventory: z
        .array(
          z.object({
            size: z.string().nullable(),
            color: z.string().nullable(),
            inventory_item_id: z.number(),
            location_id: z.number(),
            allow_negative_qty: z.boolean().default(false),
          })
        )
        .optional(),
      inventory_ids: z.array(z.number()).optional(), // Index of IDs for "inventory"
      location_ids: z.array(z.number()).optional(), // Index of location IDs in "inventory"
      collection_ids: z.array(z.number()).optional(),
    })
    .optional(),
  is_auction_live: z.boolean().optional(),
  is_auction: z.boolean().default(false),
  is_draft: z.boolean().default(false),
  is_featured: z.boolean().default(false),
  is_flat_rate: z.boolean().default(false),
  item_number: z.number().default(0),
  location_display: z.string().optional(), // for bikes
  location: z
    .object({ latitude: z.number(), longitude: z.number() })
    .optional(), // for bikes
  make: makeSchema.optional(), // for bikes
  minimum_offer_price: z.number().gte(0).optional(),
  model: z.string().optional(), // for bikes
  og_price: z.number().gte(1),
  on_sale: z.boolean().default(false),
  out_of_stock: z.boolean(),
  price: z.number().gte(1).lt(1_000_000),
  purchase_entry_id: z.string().optional(), // Purchase entry this item is associated with
  purchase_entry_price: z.number().optional(), // Price that the item was purchased for before sale on MXLocker (see purchase entries)
  ride_types: z.array(rideTypes).default([]),
  seller_id: uidSchema,
  seller_type: sellerTypeSchema.default('individual'),
  shipment: shipmentSchema.optional().nullable(), // MXLocker Labels
  shipping_costs: z
    .array(
      z.object({
        cost: z.number().gte(0).lte(9999.99),
        code: z.string().min(2).max(3),
        name: z.string(),
        enabled: z.boolean().default(false),
      })
    )
    .optional(),
  size: sizeSchema.optional(),
  sku: z.string().optional(),
  slug: z.string(),
  sold_count: z.number().int().optional(),
  sold_date: dateTimeSchema.optional(), // first sold date
  start_time: dateTimeSchema.optional(),
  stock: z.number().int(),
  thumbnail: z.string(),
  title: z.string().min(1, 'Title is required'),
  variations: z.array(variationSchema).optional(),
  video: imageSchema.optional(),
  // views: z.number().default(0), // exists on product_views col now
  year: z.number().optional(), // for bikes
  quantity: z.number().optional(), // for caching
  seller: z.any().optional(), // for caching
  attribution: z
    .object({
      page: z.string().optional(),
      section: z.string().optional(),
      data_source: z.string().optional(),
    })
    .optional(), // for caching
  from_recommend: z.boolean().default(false).optional(), // for client side use
  custom_inputs: z
    .object({
      cost: z.number().gte(0).lte(9999.99),
      fields: z.array(
        z.object({
          additional_cost: z.number().gte(0).lte(9999.99).optional(),
          id: z.string(),
          label: z.string(),
          max_length: z.number().optional(),
          placeholder: z.string().optional(),
          required: z.boolean().default(false),
          type: z
            .union([z.literal('text'), z.literal('number'), z.string()])
            .default('text'),
        })
      ),
    })
    .optional(),
  is_digital: z.boolean().optional(),
});

export const ENGINE_SIZES = [
  '50',
  '65',
  '85',
  '100',
  '105',
  '110',
  '125',
  '150',
  '200',
  '250',
  '300',
  '350',
  '450',
  '500',
] as const;

export const productSortOptions = z.union([
  z.literal('newest'),
  z.literal('priceAsc'),
  z.literal('priceDesc'),
  z.literal('mostPopular'),
  z.literal('bestMatch'),
  z.literal('soldDate'),
]);
const causeDocumentSchema = z.object({
  id: z.string(),
  img: z.string(),
  link: z.string(),
  name: z.string(),
  uid: z.string(),
  icon: z // preferably svg
    .string()
    .default('https://cdn.mxlocker.com/assets/road-2-recovery.svg'),
});
export type CauseDocument = z.infer<typeof causeDocumentSchema>;

export interface GetForYouProductsOptions {
  brand?: string; // Brand of products to return
  category?: string; // Top-level category of product
  subCategory?: 'Boots' | 'Gloves' | 'Helmets' | 'Jerseys' | 'Pants'; // Only used for riding gear ATM; could be expanded for Bike Parts in future
  userBike?: Bike; // User's bike information
  userSizes?: UserDocument['sizes']; // User's sizes for different types of riding gear
  sort?: ProductSortOption; // How results will be sorted
  searchLimit?: number; // How many products to return in one call to this function
  lastDocSnapshot?: QueryDocumentSnapshot<ProductDocument>; // What document to resume querying from (for pagination of results)
  page?: number;
}

export const getProductArgs = z.object({
  slug: z.string().optional(),
  id: z.string().optional(),
});
export type GetProductArgs = z.infer<typeof getProductArgs>;

export type ProductResultsPaginated = Promise<
  ApiResponsePaginated<ProductDocument>
>;
export type ProductResultsPaginatedByRef = Promise<
  ApiResponsePaginatedByRef<ProductDocument>
>;

export type RecommendFilters = Partial<
  Pick<ProductDocument, 'category' | 'category1' | 'category2'> & {
    brand: string | string[];
  }
>;
export type ProductSortOption = z.infer<typeof productSortOptions>;
export type ProductDocument = z.infer<typeof productSchema>;
export type Variation = z.infer<typeof variationSchema>;
export type Size = z.infer<typeof SizeSchema>;
export type Following = z.infer<typeof FollowingSchema>;
export type FacetCounts = Record<
  keyof ProductDocument | 'variations.color' | 'variations.size',
  FacetCountValue[] | undefined
>;
export type FacetCountValue = {
  count: number;
  highlighted: string;
  value: string;
};
